.home-page-hero-lg {
  height: auto;
  background-image: url("./images/studyguide-homepage-hero-no-text.png");
  max-width: 1200px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.centered {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-lower {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-lowest {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-page-hero-sm {
  height: auto;
  background-image: url("./images/paper-bg-sm.png");
  max-width: 1200px;
  background-position: center top;
  background-size: auto;
  background-repeat: no-repeat;
}

.studyguide-home-page-hero-lg {
  height: 589px;
  background-image: url("./images/studyguide-homepage-hero-no-text.png");
  max-width: 1200px;
  background-position: center top;
  background-repeat: no-repeat;
}

.page-hero {
  height: 226px;
  background-image: url("./images/page-hero-no-text.png");
  max-width: 1200px;
  background-position: center top;
  background-repeat: no-repeat;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.responsive-centered-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* Media Queries */

/* xs */
@media screen and (min-width: 0px) and (max-width: 600px) {
  .responsive-img {
    width: 25%;
    height: auto;
  }
}

/* sm */
@media screen and (min-width: 601px) and (max-width: 4000px) {
  .responsive-img {
    width: 75%;
    height: auto;
  }
}

/* md */
/* @media screen and (min-width: 901px) and (max-width: 1200px) {
    
  } */

/* lg */
/* @media screen and (min-width: 1201px) and (max-width: 1563px) {
    
  } */

/* xl */
/* @media screen and (min-width: 1564px) and (max-width: 4000px) {
    
  } */

/* Checkbox Styling */
input[type="checkbox"].checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3rem;
  height: 3rem;
  position: relative;
}
input[type="checkbox"].checkbox::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: rgba(255, 255, 255, 0);
  background-color: white;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #4c4d4f;
  font-size: 0rem;
  font-weight: bolder;
  line-height: 3rem;
  text-align: center;
  transition: background-color 350ms 0ms ease, color 350ms 250ms ease,
    font-size 350ms 250ms ease;
}
input[type="checkbox"].checkbox:checked::after {
  content: "✔";
  color: white;
  background-color: #4c4d4f;
  font-size: 2rem;
}


.welcome-box-1 {
  background-image: url("./images/welcome-page-box-1@2x.png");
  max-width: 1200px;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 70%;
}